import React, { useState } from 'react';
import Navbar from '../navbar/Navbar';
import green_gradient from "../../static/images/grren_gradient.svg";
import "./getintouch.css";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";

const GetInTouch = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [gender, setGender] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "users"), {
        name,
        email,
        contact,
        gender,
      });
      setName("");
      setEmail("");
      setContact("");
      setGender("");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000); // Hide the popup after 3 seconds
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <>

      <div className="getin_touch_main">

        <Navbar />

        <div className="getintouch_con">
          <div className="getint_bg">
            <img src={green_gradient} alt="" />
          </div>

          <div className="get_in_touch_content">
            <div className="getintouch_left">
              <h2>Access Your Future of Financial Success Today.</h2>
              <p>Get in touch to unlock personalized insights and expert advice.</p>
            </div>

            <form onSubmit={handleSubmit} action='/'>
              <h3>Fill out your details</h3>
              <div className="all_inputs">
                <div className='labelandinput'>
                  <label>Name:</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className='labelandinput'>
                  <label>Email:</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className='labelandinput'>
                  <label>Contact:</label>
                  <input
                    type="text"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                    required
                  />
                </div>

                <div className='labelandinput'>
                  <label>Gender:</label>
                  <select value={gender} onChange={(e) => setGender(e.target.value)} required className="select_input">
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>

                <div className='labelandinput'>
                  <label>Select Trading Option:</label>
                  <select required className="select_input">
                    <option value="">Trading</option>
                    <option value="male">Equity</option>
                    <option value="female">Future</option>
                    <option value="other">Intraday</option>
                  </select>
                </div>


              </div>
              <button type="submit">Submit</button>
            </form>

            {showPopup && (
              <div className="popup">
                <p>Data stored successfully!</p>
                <button onClick={() => setShowPopup(false)}>Close</button>
              </div>
            )}
          </div>
        </div>

      </div>

    </>
  );
}

export default GetInTouch;
