import React from 'react'
import "../footer/footer.css"
import footer_bg_svg from "../../static/images/footer_gradient.svg"
import tradertechlogo from "../../static/images/tradertech_monogram.svg"

const Footer = () => {
  return (
    <>
        <div className="footer_con">

            <div className="footer_bg">
                <img src={footer_bg_svg}/>
            </div>

            <div className="footer_content">

                <div className="footer_left">

                    <div className="footerlogo">
                        <img src={tradertechlogo}/>
                        <h5>tradertechadvisor</h5>
                    </div>

                    <div className="mark_businessinquiry">
                        <h3>trade as never before</h3>
                  
                        <div className="business_inquiry">
                            <h5>For business inquiry</h5>

                            <h4>Contact - +91 8850879409</h4>
                            <h4>Email - info@tradertech.in</h4>
                            <h4></h4>
                        </div>
                    </div>

                    <h5>Designed & Developed with Intelligence by <span>usmaniyaz.com</span></h5>


                </div>

                <div className="footer_right">

                    <div className="newsletter_con">
                        <h5>Email please</h5>
                        <form action="">
                            <input type="text" />
                        </form>
                        <h1>Subscribe our newsletter</h1>
                    </div>

                </div>

            </div>

        </div>
    </>
  )
}

export default Footer