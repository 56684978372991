import React from 'react';
import './WhyUs.css';
import target from "../../../images/target.png"
import robot from "../../../images/robot.png"
import assessment from "../../../images/assessment.png"
import analytics from "../../../images/analytics.png"

function WhyUs() {
  return (
    <>
      <div className="whyus_main">

        <div className="priceHeading">
          <span>WHY US</span>
          <h1>What differentiates us from the others</h1>
          <p className="headingPtag">We are a firm that combines technology, human intelligence, and knowledge to develop a customized smart trade strategy for you.</p>
        </div>


        <div class="whyus_container">

          <div class="why_us_box">
            <div><img src={target} className='whyUsImg' alt="" /></div>
            <h2 className='whyUsTitle'>Impeccable Accuracy</h2>
            <p>With upto 80% accuracy, our clients regained our client’s previous losses and maintained the upward trend of tier profits through our research.</p>
          </div>
          <div class="why_us_box">
            <div><img src={robot} className='whyUsImg' alt="" /></div>

            <h2 className='whyUsTitle'>Superior Hi-Tech Tools</h2>
            <p>For research and analysis, we employ very complex and sophisticated tech tools to bring you the best experience & accuracy.</p>
          </div>
          <div class="why_us_box">
            <div><img src={analytics} className='whyUsImg' alt="" /></div>

            <h2 className='whyUsTitle'>In-depth Market Research</h2>
            <p>To give completely accurate trading suggestions, we have a dedicated team of research experts that do in-depth analysis.</p>

          </div>
          <div class="why_us_box">
            <div><img src={assessment} className='whyUsImg' alt="" /></div>

            <h2 className='whyUsTitle'>Low Risk with High Profits</h2>
            <p>The no. 1 reason our clients stay with us long term: We help our clients to maximise their ROI while incurring minimum risk.</p>
          </div>
        </div>
        
      </div>

    </>
  );
}

export default WhyUs;
