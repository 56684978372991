import React from 'react'
import Navbar from "../navbar/Navbar"
import "./landingpage.css"
import Footer from "../footer/Footer"

import merged from "../../static/images/merged.svg"
import money_img from "../../static/images/money_img.png"

import orange1 from "../../static/images/orange1.svg"
import orange2 from "../../static/images/orange2.svg"

import rocket_img from "../../static/images/Rocket_PNG.png"
import Pricing from './PricingSection/Pricing'
import WhyUs from './WhyUs/WhyUs.jsx'

const LandingPage = () => {
  return (
    <>
      <Navbar showButton={true} />

      <div className="hero_section">

        <div className="hero_sec_bg">
          <img src={merged} />
        </div>

        <div className="hero_sec_content">

          <div className="heroheadlines">
            <h1>Unlock your financial potential and see results faster than you ever imagined.</h1>
            <p>Earn Profits Without Stressing Over Market Fluctuations</p>
          </div>

          <img src={money_img} />

        </div>

      </div>

      <div className="about_us_section">

        <div className="about_us_bg">
          <img src={orange1} />
          <img src={orange2} />
        </div>

        <div className="rocket_div">
          <img src={rocket_img} />
        </div>

        <div className="aboutus_texts">
          <h5>ABOUT US</h5>
          <h1>Supporting you in developing intelligent long-term financial stability.</h1>

          <p>Trader Tech situated in Navi Mumbai, our analyst is a SEBI-registered research analyst who has been meticulously crafted after 15 years of expertise and analysis by stock market professionals.</p>

          <p>We are continually providing our clients with the best support and expert advice in order to help them earn more while lowering the risk connected with their investments.
            Know More.</p>

        </div>



      </div>

      <Pricing />

      <WhyUs />

      {/* <div className="commitment_section">

        <div className="commitment_left">
          <h4>Our Commitments in Action</h4>
        </div>

        <div className="cards_con">
          <div className="card">

          </div>
        </div>

      </div> */}

      <Footer />

    </>
  )
}

export default LandingPage