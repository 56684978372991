import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./components/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./components/Home";
import AdminLoginPage from "./components/adminLoginPage/AdminLoginPage";
import LandingPage from "./components/landingPage/LandingPage";
import GetInTouch from "./components/getInTouch/GetInTouch";
function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/adminlogin" element={<AdminLoginPage />} />
          <Route path="/getintouch" element={<GetInTouch />} />

          <Route path="/admin" element={<PrivateRoute />}>
            <Route path="/admin" element={<Home />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
    // <h1>error  teyh55tth8575fdr47r94f4r</h1>
  );
}

export default App;

