import React from "react";
import "./pricing.css"

function Pricing() {
    return (
        <>

            <div className="pricing_main">

                <div className="priceHeading">
                    <span>WHAT WE OFFER</span>
                    <h1>Services that are specially designed for you (Our Pricing Plans)</h1>
                    <p className="headingPtag">We believe that every trader's investment needs are different. Hence we bring an army of services to choose from</p>
                </div>

                <div class="container">

                    <div className="all_boxes">

                        <div class="box">
                            <h2>REGULAR</h2>
                            <ul className="list">
                                <li>Get one research alerts of Bank Nifty / Nifty option per day.</li>
                                <li>Get well research target and stop loss on every research alert</li>
                                <li>Live market customer support available till package validity.</li>
                                <li>Risk level:- High to Very high</li>
                            </ul>
                            <h4 className="mrp">₹ 1,00,000/-</h4>
                            <h3 className="realMrp">₹ 75,000/-(Monthly Package)</h3>
                            <p className="gst">*All Prices are inclusive of GST</p>
                            <div className="btnDiv">
                                <button className="CTA_Button">Chart Now</button>
                                <button className="CTA_Button">Get Live Demo</button>
                            </div>

                        </div>

                        <div class="box">
                            <h2>PREMIUM</h2>
                            <ul className="list">
                                <li>Get one research alerts of Bank Nifty / Nifty option per day.</li>
                                <li>Expiry Special trade in Bank nifty twice a month.</li>
                                <li>Get well research target and stop loss on every research alert.</li>
                                <li>Live market customer support available till package validity.</li>
                                <li>Risk level: - High to Very high</li>
                            </ul>
                            <h4 className="mrp">₹ 5,00,000/-</h4>
                            <h3 className="realMrp">₹ 3,50,000/- (Quarterly Package)</h3>
                            <p className="gst">*All Prices are inclusive of GST</p>
                            <div className="btnDiv">
                                <button className="CTA_Button">Chart Now</button>
                                <button className="CTA_Button">Get Live Demo</button>
                            </div>

                        </div>

                        <div class="box">
                            <h2>COMBO</h2>
                            <ul className="list">
                                <li>Get one research alerts of Bank Nifty / Nifty option per day.</li>
                                <li>Expiry Special trade in Finnifty/BTST Level.</li>
                                <li>Get well research target and stop loss on every research alert</li>
                                <li>Live market customer support available till package validity</li>
                                <li>Risk level: - High to Very high.</li>
                            </ul>
                            <h4 className="mrp">₹ 8,25,000/-</h4>
                            <h3 className="realMrp">₹ 6,25,000/-(Half Yearly Package)</h3>
                            <p className="gst">*All Prices are inclusive of GST</p>
                            <div className="btnDiv">
                                <button className="CTA_Button">Chart Now</button>
                                <button className="CTA_Button">Get Live Demo</button>
                            </div>

                        </div>

                        <div class="box">
                            <h2>PLATINUM</h2>
                            <ul className="list">
                                <li>Get one research alerts of Bank Nifty / Nifty option per day.</li>
                                <li>Expiry Special trade in Bank nifty/Nifty/Finnifty.</li>
                                <li>Get well research target and stop loss on every research alert.</li>
                                <li>Relationship manager support for trade alerts.</li>
                                <li>Live market customer support available till package validity.</li>
                                <li>Risk level: - High to Very high</li>
                            </ul>
                            <h4 className="mrp">₹  16,50,000/-</h4>
                            <h3 className="realMrp">₹ 12,25,000/- (Yearly Package)</h3>
                            <p className="gst">*All Prices are inclusive of GST</p>
                            <div className="btnDiv">
                                <button className="CTA_Button">Chart Now</button>
                                <button className="CTA_Button">Get Live Demo</button>
                            </div>

                        </div>

                    </div>



                </div>

            </div>


        </>
    );
}

export default Pricing;

