import React from 'react'
import { Link } from 'react-router-dom'

import tradertechlogo from "../../static/images/tradertech_monogram.svg"

import "./navbar.css"

const Navbar = ({ showButton }) => {
    return (
        <>
            <div className="navbar_con">
                <Link to="/" className='logo_link'>
                    <div className="logo_con">
                        <img src={tradertechlogo} alt="Logo not found" />
                        <h5>tradertechadvisor</h5>
                    </div>
                </Link>

                {showButton && (
                    <Link to="/getintouch">
                        <button>
                            Get in touch
                        </button>
                    </Link>
                )}

            </div>
        </>
    )
}

export default Navbar
