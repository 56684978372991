
import React from "react";
import Navbar from "../navbar/Navbar";
import { Link } from "react-router-dom";
import "./adminloginpage.css";
import { useState } from "react";
import admin_grad_violet from "../../static/images/violet_grad.svg";
import admin_grad_pink from "../../static/images/pink_grad.svg";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";

const AdminLoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/admin");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <Navbar />

      <div className="adminlogin_con">
        <div className="admin_login_bg">
          <img src={admin_grad_violet} />
          <img src={admin_grad_pink} />
        </div>

        <div className="adminlogin_content">
          <div className="admin_login_top">
            <h1>Admin Login</h1>
            <h4>Enter Credentials</h4>
          </div>
          {error && <p>Invalid Credential</p>}

          <div className="admin_login_bottom">
            <form onSubmit={handleSubmit}>
              <div className="admin_login_inputs">
                <div className="labelandinput">
                  <label>Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                  />
                </div>

                <div className="labelandinput">
                  <label>Password</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                  />
                </div>
              </div>

              <button type="submit">Login</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLoginPage;
